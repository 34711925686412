import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import CallbackView from '../views/CallbackView.vue'
import CommandsView from '../views/CommandsView.vue';
import TeamView from '../views/TeamView.vue';
import SupportView from '../views/SupportView.vue';
import MarkdownView from '../views/MarkdownView.vue';
import ErrorView from '../views/ErrorView.vue';

const pages = import.meta.glob('../docs/*.md');

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            path: '/callback',
            name: 'callback',
            component: CallbackView,
        },
        {
            path: '/commands',
            name: 'commands',
            component: CommandsView
        },
        {
            path: '/team',
            name: 'team',
            component: TeamView
        },
        {
            path: '/support',
            name: 'premium',
            component: SupportView
        },
        {
            path: '/docs',
            name: 'docs',
            component: MarkdownView,
            children: Object.entries(pages).map(([path, component]) => ({
                path: path.replace('../docs/', '').replace('.md', ''),
                component
            }))
        },
        {
            path: '/:pathMatch(.*)*',
            component: ErrorView
        }
    ]
});

export default router;
