<script setup lang="ts">
import BaseBox from '../components/base/Box.vue';
import { useHead } from 'unhead';
import { useRoute } from 'vue-router';
import axios from 'axios';

export interface CallbackData {
    osu_id: number,
    discord_id: number,
}

const SERVER_URL = import.meta.env.VITE_SERVER_URL;

const route = useRoute();

let error: any = null;
let data: CallbackData | null = null;

async function getCallback() {
    const resp = await axios.get("/api/callback/", {
        params: route.query
    }).catch(e => {
        error = e;
    });
    data = resp?.data.data;
}

await getCallback();

useHead({
    title: 'OAuth · Sunny'
});
</script>

<template>
    <div class="mt-16 mb-8">
        <div class="flex justify-center">
            <BaseBox size="w-3/4 md:w-1/2">
                <div v-if="error || !data">
                    <div class="flex justify-center">
                        <h1 class="text-2xl font-bold text-red-400"> Account failed to link. </h1>
                    </div>
                    <div class="flex justify-center">
                        <p class="mt-3">Please try again later.</p>
                    </div>
                    <div class="flex justify-center">
                        <p class="mt-3">If the issue persists, please contact us on the <a :href="SERVER_URL"
                                target="_blank" rel="noopener noreferrer" class="text-gray-400">support
                                server</a>.</p>
                    </div>
                </div>

                <div v-else class="flex justify-center flex-col">
                    <div class="flex justify-center">
                        <h1 class="text-2xl font-bold text-green-400"> Account linked successfully. </h1>
                    </div>
                    <div class="flex justify-center">
                        <p class="mt-3">You may now close the webpage.</p>
                    </div>
                </div>
            </BaseBox>
        </div>
    </div>
</template>